import React from 'react'
import ReactHookFormWrapper from '../../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper';
import NumberSettingsField from '../Fields/NumberSettingsField';
import { OpenOrderFieldSecond, NewOpenOrderFieldSecond, OpenPositionFieldSecond, TreadDoneSecond, VerifyAfterZeroFieldSecond, ChangeStatusOffsetSecond, VerifyFieldSecond, PriceDifferenceOrderFieldSecond, LeverageFieldSecond, StopLossFieldSecond } from "../LabelAndNameFieldsSecond"
import Button from '../../sections/Button/Button';
import ToggleButtonField from '../Fields/ToggleButtonField';
const SettingSecondForm = ({ formMethodsSecondForm, onSubmitSecondForm }: any) => {

    return (
        <div>
            <ReactHookFormWrapper
                formMethods={formMethodsSecondForm}
                onSubmit={onSubmitSecondForm}
                hiddenButton
                className={`flex flex-col justify-start py-4  rounded-2xl w-full`}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
                    <NumberSettingsField nameFieldProps={StopLossFieldSecond.name} labelFieldProps={StopLossFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={PriceDifferenceOrderFieldSecond.name} labelFieldProps={PriceDifferenceOrderFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={VerifyFieldSecond.name} labelFieldProps={VerifyFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={VerifyAfterZeroFieldSecond.name} labelFieldProps={VerifyAfterZeroFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={OpenPositionFieldSecond.name} labelFieldProps={OpenPositionFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={NewOpenOrderFieldSecond.name} labelFieldProps={NewOpenOrderFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={OpenOrderFieldSecond.name} labelFieldProps={OpenOrderFieldSecond.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={ChangeStatusOffsetSecond.name} labelFieldProps={ChangeStatusOffsetSecond.label} notRequired={true} />
                    <div className="mt-3 px-3">
                        <ToggleButtonField nameFieldProps={TreadDoneSecond.name} labelFieldProps={TreadDoneSecond.label} />
                    </div>
                </div>
                <div className="flex gap-2 pt-4  justify-start">
                    <>
                        <Button
                            color="primary"
                            size="md"
                            type="submit"
                            className={{ extra: "px-4 py-2 rounded-lg" }}
                        >
                            اعمال تنظیمات
                        </Button>
                    </>
                </div>
            </ReactHookFormWrapper>
        </div>

    )
}

export default SettingSecondForm

