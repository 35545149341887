import { configureStore } from '@reduxjs/toolkit';
import userReducer, { UserSliceType } from "./slices/userSlice"


export interface RootState{
    user:UserSliceType
}

export const store = configureStore({
    reducer: {
      user: userReducer,
      
      // Add other reducers here
    },
  });


  export type AppDispatch = typeof store.dispatch;

  