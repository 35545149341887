
import React, { useEffect, useState } from 'react';
import { centralNodesocketClient as socketClient } from "../../Socket/centralNodeSocket";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setSignalStatus } from '../../redux/slices/userSlice';

type OrderbookData = {
  a: number;
  b: number;
  d: number;
  p: number;
  q: number;
  j?:number
};

function useTestexchange() {
  const dispatch = useDispatch<AppDispatch>();

  const [allBtc, setAllBtc] = useState<OrderbookData>();
  const [allBybit, setAllBybit] = useState<OrderbookData>();

  const [btc_100_Bitstamp, setBtc_100_Bitstamp] = useState<OrderbookData>();
  const [Eth_100_Bitstamp, setEth_100_Bitstamp] = useState<OrderbookData>();

  const [btc_400_Bitstamp, setBtc_400_Bitstamp] = useState<OrderbookData>();
  const [Eth_400_Bitstamp, setEth_400_Bitstamp] = useState<OrderbookData>();

  const [btc_100_okx, setBtc_100_okx] = useState<OrderbookData>();
  const [Eth_100_okx, setEth_100_okx] = useState<OrderbookData>();
  const [btc_400_okx, setBtc_400_okx] = useState<OrderbookData>();
  const [Eth_400_okx, setEth_400_okx] = useState<OrderbookData>();

  const [btc_100_bybit, setBtc_100_bybit] = useState<OrderbookData>();
  const [eth_100_bybit, setEth_100_bybit] = useState<OrderbookData>();
  const [btc_400_bybit, setBtc_400_bybit] = useState<OrderbookData>();
  const [eth_400_bybit, setEth_400_bybit] = useState<OrderbookData>()
  const [filAsks, setFilAsks] = useState<number[]>([]);
  const [filBids, setFilBids] = useState<number[]>([]);
  const [ethAsks, setEthAsks] = useState<number[]>([]);
  const [ethBids, setEthBids] = useState<number[]>([]);
  const [btcAsks, setBtcAsks] = useState<number[]>([]);
  const [btcBids, setBtcBids] = useState<number[]>([]);
  const [filAskskaam, setFilAskskam] = useState<number[]>([]);
  const [filBidskam, setFilBidskam] = useState<number[]>([]);
  const [ethAskskam, setEthAskskam] = useState<number[]>([]);
  const [ethBidskam, setEthBidskam] = useState<number[]>([]);
  const [btcAskskam, setBtcAskskam] = useState<number[]>([]);
  const [btcBidskam, setBtcBidskam] = useState<number[]>([]);
  const [btcPriceBinance, setBtcPriceBinance] = useState<number>(0)
  const [ethPriceBinance, setEthPriceBinance] = useState<number>(0)
  const [bybitPrice, setBybitPrice] = useState<number>(0)
  const [btcByBitStatus , setbtcByBitStatus] = useState<"DEC"|"ASC">("ASC")

  const [btcPriceBinanceStatus, btcEthPriceBinanceStatus] = useState<"DEC"|"ASC">("ASC")
  const [EthPriceBinanceStatus, setEthPriceBinanceStatus] = useState<"DEC"|"ASC">("ASC")
  const [signalperiodStatus, setSignalPeriodStatus] = useState<"short"|"long"|"STRONG LONG"|"STRONG SHORT"|"">("")

  const [orderbookperiodStatus, setorderbookPeriodStatus] = useState<"short"|"long"|""|null>("")

  const [eth_20_binance, setEth_20_binance] = useState<OrderbookData>();


  const [eth_all_bybit, setEth_all_bybit] = useState<OrderbookData>();
  const [eth_10_binance, setEth_10_binance] = useState<OrderbookData>();
  interface MarketData {
    asks: number;
    bids: number;
  }
  
  useEffect(() => {
    socketClient.emit("join-room", "bybit-ETHUSDT-orderbook-all");
    socketClient.emit("join-room", "binance-ETHUSDT-orderbook-10$");


    socketClient.emit("join-room", "binance-BTCUSDT-orderbook-all");
    socketClient.emit("join-room", "bybit-BTCUSDT-orderbook-all");
    socketClient.emit("join-room", "binance-ETHUSDT-orderbook-20$");


    socketClient.emit("join-room", "bitstamp-BTCUSDT-orderbook-100$");
    socketClient.emit("join-room", "bitstamp-ETHUSDT-orderbook-100$");
    socketClient.emit("join-room", "bitstamp-BTCUSDT-orderbook-400$");
    socketClient.emit("join-room", "bitstamp-ETHUSDT-orderbook-400$");

    socketClient.emit("join-room", "okx-BTCUSDT-orderbook-100$");
    socketClient.emit("join-room", "okx-ETHUSDT-orderbook-100$");
    socketClient.emit("join-room", "okx-BTCUSDT-orderbook-400$");
    socketClient.emit("join-room", "okx-ETHUSDT-orderbook-400$");
    


    socketClient.emit("join-room", "bybit-BTCUSDT-orderbook-100$");
    socketClient.emit("join-room", "bybit-ETHUSDT-orderbook-100$");
    socketClient.emit("join-room", "bybit-BTCUSDT-orderbook-400$");
    socketClient.emit("join-room", "bybit-ETHUSDT-orderbook-400$");

    socketClient.emit("join-room", "binance-ETHUSDT-price");
    socketClient.emit("join-room", "binance-BTCUSDT-price");
    socketClient.emit("join-room", "bybit-BTCUSDT-price");
    socketClient.emit("join-room", "signal-period");
    socketClient.emit("join-room", "orderbook-period");
    socketClient.emit("join-room", "ask-bids-yes");







    socketClient.on("bybit-ETHUSDT-orderbook-all-res", (data: OrderbookData) => {            
      // console.log(" eth all",data)
      if (data.q > 0) {
      
        data.j = 1;
      } else {
        data.j = 0;
      }
     setEth_all_bybit(data)
          });
       
        
    socketClient.on("binance-ETHUSDT-orderbook-10$-res", (data: OrderbookData) => {            
      // console.log(" eth 0$ ",data)   
    setEth_10_binance(data)
                });
             



    
    socketClient.on("binance-ETHUSDT-orderbook-20$-res", (data: OrderbookData) => {            
      // console.log(" eth 20$ ",data)
      
      
     
      setEth_20_binance(data)
          });
      //  /  mohandes in oky.
      
      socketClient.on("signal-period-res", (data: any) => {            
        console.log("Signal Status =========== > ",data,"time => ",new Date() )
        // dispatch(data);
        if(data[1]=="cn_1"){
          localStorage.setItem("signal_1", data[0])
        }
        if(data[1]=="cn_2"){
          localStorage.setItem("signal_2", data[0])
  
        }
        if(data[1]=="cn_3"){
          console.log("Received cn_3:", data[0]);
          localStorage.setItem("signal_3", data[0])
  
        }
        if(data[1]=="cn_4"){
          console.log("Received cn_4:", data[0]);
          localStorage.setItem("signal_4", data[0])
  
        }
        if(data[1]=="cn_5"){
          console.log("Received cn_5:", data[0]);
          localStorage.setItem("signal_5", data[0])
  
        }
        if(data[1]=="cn_6"){
          console.log("Received cn_6:", data[0]);
          localStorage.setItem("signal_6", data[0])
  
        }
        if(data[1]=="cn_7"){
          console.log("Received cn_7:", data[0]);
          localStorage.setItem("signal_7", data[0])
  
        }
        if(data[1]=="cn_8"){
          console.log("Received cn_8:", data[0]);
          localStorage.setItem("signal_8", data[0])
  
        }
        if(data[1]=="cn_fil"){
          console.log("Received cn_6:", data[0]);
          localStorage.setItem("signal_fil", data[0])
  
        }
        if(data[1]=="cn_btc"){
          console.log("Received cn_7:", data[0]);
          localStorage.setItem("signal_btc", data[0])
  
        }
        if(data[1]=="cn_eth"){
          console.log("Received cn_8:", data[0]);
          localStorage.setItem("signal_eth", data[0])
  
        }
        if(data[1]=="cn_busel"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_busel", data[0])
  
        }
        if(data[1]=="cn_ethask"){
          console.log("Received cn_8:", data[0]);
          localStorage.setItem("cn_ethask", data[0])
  
        }
        if(data[1]=="cn_ethask22"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_ethask22", data[0])
  
        }
        if(data[1]=="cn_pish10"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_pish10", data[0])
  
        }
        if(data[1]=="cn_allbtc"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_allbtc", data[0])
  
        }
        if(data[1]=="cn_pish50"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_pish50", data[0])
  
        }
        if(data[1]=="cn_pish20"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_pish20", data[0])
  
        }
        if(data[1]=="cn_askbidseth22"){
          console.log("Received cn_busel:", data[0]);
          localStorage.setItem("cn_askbidseth22", data[0])
  
        }
      
      if(data[1]=="cn_tarkibpishha"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_tarkibpishha", data[0])

      }
      if(data[1]=="cn_pish5"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_pish5", data[0])

      }
      if(data[1]=="cn_15saniye"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_15saniye", data[0])

      }
      if(data[1]=="cn_pish3"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_pish3", data[0])
        
      }
      if(data[1]=="cn_hamjahat"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_hamjahat", data[0])

      }
      if(data[1]=="cn_pish3hamjahat"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_pish3hamjahat", data[0])

      }
      if(data[1]=="cn_alleth"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_alleth", data[0])

      }
      if(data[1]=="cn_erhhamjahatkam"){
        console.log("Received cn_busel:", data[0]);
        localStorage.setItem("cn_erhhamjahatkam", data[0])

      }


        setSignalPeriodStatus(data)
            });
            
    socketClient.on("orderbook-period-res", (data: any) => {            
      // console.log("Orderbook Status =========== > ",data)
      setorderbookPeriodStatus(data)
      localStorage.setItem("orderbooksocket", data)

          });
          socketClient.on("ask-bids-data", (data: { currency: string, asks: number[], bids: number[], asks_kam: number[], bids_kam: number[] }) => {
            console.log("Received ask-bids-data:", data);

            switch (data.currency) {
              case 'FIL':
    setFilAsks(data.asks);
    setFilBids(data.bids);
    setFilAskskam( data.asks_kam);
    setFilBidskam( data.bids_kam);
    break;
  case 'ETH':
    setEthAsks(data.asks);
    setEthBids(data.bids);
    setEthAskskam( data.asks_kam);
    setEthBidskam(data.bids_kam);
    break;
  case 'BTC':
    setBtcAsks(data.asks);
    setBtcBids(data.bids);
    setBtcAskskam( data.asks_kam);
    setBtcBidskam( data.bids_kam);
                break;
              default:
                console.warn("Unknown currency:", data.currency);
            }
          });
      
    socketClient.on("bybit-BTCUSDT-price-res", (data: number) => {            
      // console.log("BTC BYBIT ",data)
      
      setbtcByBitStatus(bybitPrice<data?"ASC":"DEC")
      
     
        setBybitPrice(data)
          });

    socketClient.on("binance-BTCUSDT-price-res", (data: number) => {            
      // console.log("btc BTCUSDT ",data)
      
        btcEthPriceBinanceStatus(btcPriceBinance<data?"ASC":"DEC")
      
     
      setBtcPriceBinance(data)
          });
  socketClient.on("binance-ETHUSDT-price-res", (data: number) => {            
    setEthPriceBinanceStatus(ethPriceBinance<data?"ASC":"DEC")
    setEthPriceBinance(data)
          });
          

  // bybit 100 btc
  socketClient.on("bybit-BTCUSDT-orderbook-100$-res", (data: OrderbookData) => {            
    setBtc_100_bybit(data);
    });
     // bybit 400 btc
  socketClient.on("bybit-BTCUSDT-orderbook-400$-res", (data: OrderbookData) => {            
    setBtc_400_bybit(data);
    });
// bybit 100 btc
  socketClient.on("bybit-ETHUSDT-orderbook-100$-res", (data: OrderbookData) => { 
    setEth_100_bybit(data);
    });
 // bybit 400 btc
 socketClient.on("bybit-ETHUSDT-orderbook-400$-res", (data: OrderbookData) => {            
  setEth_400_bybit(data);
  });












    // okx 100 btc
    socketClient.on("okx-BTCUSDT-orderbook-100$-res", (data: OrderbookData) => {            
      setBtc_100_okx(data);
      });
       // okx 400 btc
    socketClient.on("okx-BTCUSDT-orderbook-400$-res", (data: OrderbookData) => {            
      setBtc_400_okx(data);
      });
 // okx 100 btc
    socketClient.on("okx-ETHUSDT-orderbook-100$-res", (data: OrderbookData) => { 
  setEth_100_okx(data);
      });
   // okx 400 btc
   socketClient.on("okx-ETHUSDT-orderbook-400$-res", (data: OrderbookData) => {            
    setEth_400_okx(data);
    });





    socketClient.on("bitstamp-BTCUSDT-orderbook-400$-res", (data: OrderbookData) => {            
      setBtc_400_Bitstamp(data);
  
      });
      socketClient.on("bitstamp-ETHUSDT-orderbook-400$-res", (data: OrderbookData) => {            
        setEth_400_Bitstamp(data);
    
        });



    socketClient.on("bitstamp-BTCUSDT-orderbook-100$-res", (data: OrderbookData) => {            
      setBtc_100_Bitstamp(data);
  
      });
    socketClient.on("bitstamp-ETHUSDT-orderbook-100$-res", (data: OrderbookData) => {            
    setEth_100_Bitstamp(data);
    });
    socketClient.on("binance-BTCUSDT-orderbook-all-res", (data: OrderbookData) => {            
      if (data.q > 0) {
      
        data.j = 1;
      } else {
        data.j = 0;
      }
      setAllBtc(data); 

    });
    socketClient.on("bybit-BTCUSDT-orderbook-all-res", (data: OrderbookData) => {
      if (data.q > 0) {
      
        data.j = 1;
      } else {
        data.j = 0;
      }
      setAllBybit(data);
    });

    return () => {
      // Cleanup on component unmount
      // socketClient.off("binance-BTCUSDT-orderbook-50$-res");
    };
  }, []); // Empty dependency array means this effect runs once on component mount
  // console.log("FIL Asks: ", filAsks);
  // console.log("FIL Bids: ", filBids);
  // console.log("ETH Asks: ", ethAsks);
  // console.log("ETH Bids: ", ethBids);
  // console.log("BTC Asks: ", btcAsks);
  // console.log("BTC Bids: ", btcBids);
  return { allBtc ,allBybit,btc_100_Bitstamp , Eth_100_Bitstamp , btc_400_Bitstamp ,Eth_400_Bitstamp, signalperiodStatus ,orderbookperiodStatus ,eth_20_binance ,
    btc_100_okx , Eth_100_okx , btc_400_okx ,Eth_400_okx ,btc_100_bybit , eth_100_bybit , btc_400_bybit ,eth_400_bybit , btcPriceBinance , btcPriceBinanceStatus ,  EthPriceBinanceStatus , ethPriceBinance , btcByBitStatus
  ,  eth_all_bybit,eth_10_binance   ,  filAsks,
  filBids,
  ethAsks,
  ethBids,
  btcAsks,
  btcBids
  ,  filAskskaam,
  filBidskam,
  ethAskskam,
  ethBidskam,
  btcAskskam,
  btcBidskam
  };
}

export default useTestexchange;
