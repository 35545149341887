import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/slices/userSlice";
import { Api } from "../../Api";
import { useNavigate } from "react-router-dom";
import useReactHookFormWrapper from "../sections/Form/ReactHookFormWrapper/hooks/useReactHookFormWrapper";
import { toast } from "react-toastify";
import { removeItemFromObjectWhenIsNullOrEmptyString } from "../../utils/isNullOrEmptyString";
import { passwordFieldName } from "./Fileds/PasswordField";
import { usernameFildeName } from "./Fileds/UsernameField";
import useAxios from "../../Hooks/request/hooks/useAxios";

interface LoginFormType {
  uname: string;
  password: string;
}
function UseLogin() {
  const { setValue, watch, handleSubmit } = useForm<LoginFormType>();
  const [loginResponse, login] = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function onSubmitHandler(data: any) {
    const finalData = {
      uname: data[usernameFildeName],
      password: data[passwordFieldName],
    };
    const url = Api.Login;

    const res = await login({
      url,
      method: "POST",
      data: {
        ...removeItemFromObjectWhenIsNullOrEmptyString(finalData),
      },
    }).then(async (response: any) => {
      console.log("response: ", response);
      if (response.status) {
        navigate("/Dashboard");

        dispatch(
          setUserInfo({
            name: response.user.name,
            token: "token",
            role: response.user.role
          })
        );
        localStorage.setItem("token", "token")
        localStorage.setItem("role", response.user.role)
        toast.success("خوش آمدید")
      }


    }).catch((err: any) => {
      toast.error(err?.msg)

    });


  };

  async function onSubmitForgetpass(data: any) {
    navigate("/forgetpass");

  }
  const { formMethods, onSubmit } = useReactHookFormWrapper({
    onSubmitHandler,
  });


  return {
    onSubmit,
    formMethods,
    onSubmitForgetpass
  };
}
export default UseLogin;
