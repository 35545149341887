import axios from "axios"
import { toast } from "react-toastify";
import { getResponseError } from "./utils/getResponse";
import { constants } from "../../constants/constants";
// import getToken from "@/utils/auth/getToken";


// It need the word 'api' to be written at the end of the url 

export const apiAddressCreator = (url: string): string => {
  return constants.apiUrl + url
}

const axiosProvider = axios.create({
  // withCredentials: true,
  baseURL: constants.apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
})

axiosProvider.interceptors.request.use(async (request: any) => {
  // request.headers["x-apikey"] = [process.env.apiKey];
  // request.headers["Access-Control-Allow-Origin"] = ["*"];

  // const token = getToken();
  // if (token) {
  //   request.headers["Authorization"] = [`Bearer ${token}`];
  // }
  return request;
});

axiosProvider.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log("rrrr: ",error);
  
  // if (error?.request?.status == 200) {
  //   toast.error(getResponseError(error))
  // }
  return Promise.reject(error);
});

axiosProvider.interceptors.response.use(
  response => response,
  err => {
    //TODO  implement logout user when his request has 401 or 403 error code in useAxios
    // if (err?.response?.status === 401) {
    //     localStorage.clear()
    //     window.location.href = window.location.origin + '/login'
    // }

    return Promise.reject(err)
  }
)

export { axiosProvider }