import Ls from "./customLocalStorage";


function getRole() {
    const role = Ls.get("role");
    if (!role) return

    try {
        return role
    } catch (err) {
        return
    }
}

export default getRole;