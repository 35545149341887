
// Second Fields
export const StopLossFieldSecond = { label: "اختلاف قیمت (stopLoss)", name: "stopLoss_second" }
export const VerifyFieldSecond = { label: "اختلاف برای (verify)", name: "verify_second" }
export const PriceDifferenceOrderFieldSecond = { label: "اختلاف قیمت در لحظه ثبت اردر", name: "price_difference_order_second" }
export const LeverageFieldSecond = { label: "leverage", name: "leverage_second" }
export const OpenPositionFieldSecond = { label: "گام برای (open position)", name: "open_position_second" }
export const OpenOrderFieldSecond = { label: "گام برای open اول (open order)", name: "open_order_second" }
export const NewOpenOrderFieldSecond = { label: "گام برای cancel open (new open order)", name: "new_open_order_second" }
export const VerifyAfterZeroFieldSecond = { label: "وریفای (بعد از صفر)", name: "verify_after_zero_second" }
export const TreadDoneSecond = { label: "ترید انجام شود", name: "tread_done_second" }
export const ChangeStatusOffsetSecond = { label: "متغییر یک سنت", name: "change_status_offset_second" }
