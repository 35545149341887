import React from 'react'
import { socketClient } from "../../Socket";
import { useEffect, useState } from "react";
import useAxios from '../../Hooks/request/hooks/useAxios';
import { Api } from '../../Api';
import { toast } from 'react-toastify';
import { centralNodesocketClient } from '../../Socket/centralNodeSocket';

export interface StatusRecordType {
  // FutureHistoryData:[],
  isOpen: boolean;
  entryPrice: number;
  orderStatus: string;
  tolerancePrice: number;
  endPrice: number;
  checkAfterZero: boolean;
  checkAfterZeroStep: boolean;
  bingEntryPrice: number;
  bingOrderStatus: string;
  secondConditionForZero: boolean;
  demoPositionStepOrder: string;
  price: number;
  balance:number
  status: string;
  isNewStep: boolean;
  step: number;
  asksOneCent: string;
  bidsOneCent: string;
  bidsOneCentArray: [string, string][];
  asksOneCentArray: [string, string][];
  isPendingForPrice: boolean;
  cancelOrderCount: number;
  priceStep: number;
  priceDiffAfterZero: number;
  bingBTCPrice: number;
  bingOrder: any;
  priceQueue: any[];
  isPendingOrder: boolean;
  bingTrade: boolean;
  demoTrade: boolean;
  type: string
}

function useDemo() {
  const [statusRecord, setStatusRecord] = useState<StatusRecordType[]>([])
  const [data, setData] = useState<any>();
  const [signalFront, setSignalFront] = useState<any>();
  const [ethsignalFront, setEthSignalFront] = useState<any>();
  const [deleteFuturesHistoryResponse, deleteFuturesHistory] = useAxios()
  const [reload, setReload]=useState<boolean>(false)
  const [count, setCount]=useState<number>(0)
  const [bingxData, setBingxData]=useState<any>(0)

  async function deleteFuturesHistoryHandler() {
    const url = Api.deleteFuturesHistory;

    const res = await deleteFuturesHistory({
      url,
      method: "DELETE",
    }).then((res) => {
      toast.success(res.message)
    });
  }

  useEffect(() => {
    console.log("get Socket ")
    socketClient.emit("join-page", "statusRecord");
    socketClient.emit("join-page", "bingOrderStatus");

    centralNodesocketClient.emit("join-room", "ETH-signals");
    centralNodesocketClient.emit("join-room", "BTC-signals");
   
   
   
   
    socketClient.on("status", (data) => {
      setStatusRecord(data)
      // console.log("status****: ",data.length, data);
      setData(data.FutureHistoryData)
    })
   
    socketClient.on("bing-order", (data) => {
      console.log("thisis BING => ",data)
      setBingxData(data)
    })
   
   
   
   
    centralNodesocketClient.on("BTC-signals-res", (data) => {
      setSignalFront([...data])
      console.log("btc signals: ", data);
    })
    centralNodesocketClient.on("ETH-signals-res", (data) => {
      setEthSignalFront([...data])
      console.log("eth signals: ", data);
    })

  }, [reload]);
  
  useEffect(() => {
    const t = setInterval(()=>{
      setReload((old)=>!old)
      console.log("Reload ...")
    },60000)

  }, []);

  
  
  
  return { statusRecord, data, deleteFuturesHistoryHandler, signalFront, ethsignalFront, bingxData };
}
export default useDemo;
