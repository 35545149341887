import React from 'react'
import useHistoryTradeBingx from './useHistoryTrade'
function HistoryTradeBingx() {
  const { tradeHistoryState } = useHistoryTradeBingx();

  return (
      <div className="overflow-x-auto w-full py-4">
          <table className="table-auto w-full border-collapse border border-gray-200">
              <thead>
                <h3>Symbol</h3>
                  <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-4 py-2">Profit</th>
                      <th className="border border-gray-300 px-4 py-2">Position Side</th>
                      <th className="border border-gray-300 px-4 py-2">Type</th>
                      <th className="border border-gray-300 px-4 py-2">Avg Price</th>
                      <th className="border border-gray-300 px-4 py-2">Status</th>
                      <th className="border border-gray-300 px-4 py-2">Commission</th>
                      <th className="border border-gray-300 px-4 py-2">سود</th>
                  </tr>
              </thead>
              <tbody>
                  {tradeHistoryState&& tradeHistoryState.map((trade:any, index:any) => (
                    
                      <tr key={index} className={index % 2 === 0 ? 'bg-[#52516b] bg-opacity-90'  : ''}>
                          <td className="border border-gray-300 px-4 py-2">{trade.profit}</td>
                          <td className="border border-gray-300 px-4 py-2">{trade.positionSide}</td>
                          <td className="border border-gray-300 px-4 py-2">{trade.type}</td>
                          <td className="border border-gray-300 px-4 py-2">{trade.avgPrice}</td>
                          <td className="border border-gray-300 px-4 py-2">{trade.status}</td>
                          <td className="border border-gray-300 px-4 py-2">{trade.commission}</td>
                          <td className="border border-gray-300 px-4 py-2">{(Number(Math.abs(trade.commission))-Number(Math.abs(trade.profit))).toFixed(7)}</td>
                      </tr>
                  ))}
              </tbody>
          </table>
      </div>
  );
}

export default HistoryTradeBingx;
