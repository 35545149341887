import clsx from "clsx";
import React, { ReactElement, useEffect,ReactNode, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "../Dashboard";
import Snapshot from "../RealSnapshot";
import Menu from "./Menu";
import Content from "./Content";
import { socketClient } from "../../Socket";
import { toast } from "react-toastify";

function Layout() {
  return (
    <div className="w-full min-h-screen relative flex pt-3 ">
      <Menu />
      <Content />
    </div>
  );
}



export default Layout;
