import removeToken from "./removeToken";
function useLogout() {

    function logoutHandler() {
        removeToken()
    }

    return logoutHandler
}

export default useLogout