import io from "socket.io-client";
import { constants , centralUrl } from "../constants/constants";

console.log("constants: "," constants.apiUrl");
console.log("centralUrl: ",centralUrl );
const centralNodesocketClient = io(constants.centralUrl, { transports: ["websocket"],  reconnection: true,
reconnectionAttempts: Infinity,
reconnectionDelay: 1000,
reconnectionDelayMax: 5000, });

centralNodesocketClient.on("connect", () => {
  console.log("joinqqq");
});

export { centralNodesocketClient };