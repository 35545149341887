import React from 'react'
import ReactHookFormWrapper from '../../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper';
import NumberSettingsField from '../Fields/NumberSettingsField';
import { OpenOrderField, NewOpenOrderField, OpenPositionField, TreadDone, VerifyAfterZeroField, ChangeStatusOffset, VerifyField, PriceDifferenceOrderField, LeverageField, StopLossField, withDemo } from "../LabelAndNameFields"
import Button from '../../sections/Button/Button';
import ToggleButtonField from '../Fields/ToggleButtonField';
const SettingFirstForm = ({ formMethods, onSubmit }: any) => {

    return (
        <div>
            <ReactHookFormWrapper
                formMethods={formMethods}
                onSubmit={onSubmit}
                hiddenButton
                className={`flex flex-col justify-start py-4  rounded-2xl w-full`}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
                    <NumberSettingsField nameFieldProps={StopLossField.name} labelFieldProps={StopLossField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={PriceDifferenceOrderField.name} labelFieldProps={PriceDifferenceOrderField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={VerifyField.name} labelFieldProps={VerifyField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={VerifyAfterZeroField.name} labelFieldProps={VerifyAfterZeroField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={OpenPositionField.name} labelFieldProps={OpenPositionField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={NewOpenOrderField.name} labelFieldProps={NewOpenOrderField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={OpenOrderField.name} labelFieldProps={OpenOrderField.label} notRequired={true} />
                    <NumberSettingsField nameFieldProps={ChangeStatusOffset.name} labelFieldProps={ChangeStatusOffset.label} notRequired={true} />


                </div>
                <div> 
                <div className="my-8 px-3">
                        <ToggleButtonField nameFieldProps={withDemo.name} labelFieldProps={withDemo.label} />
                    </div>
                    <div className="my-8 px-3">
                    <ToggleButtonField nameFieldProps={TreadDone.name} labelFieldProps={TreadDone.label} />
                </div>
                    </div>
                <div className="flex gap-2 pt-4  justify-start">
                    <>
                        <Button
                            color="primary"
                            size="md"
                            type="submit"
                            className={{ extra: "px-4 py-2 rounded-lg" }}
                        >
                            اعمال تنظیمات
                        </Button>
                    </>
                </div>
            </ReactHookFormWrapper>
        </div>

    )
}

export default SettingFirstForm

