import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Dashboard';
import RealSnapshot from '../RealSnapshot';
import Header from "./Header";
import SnapshotHistory from '../SnapshotHistory';
import Settings from '../Settings';
import Demo from '../Demo';
import SnapShotDetails from '../SnapShotDetails';
import BingxSettings from '../BingxSettings';
import TestSignal from '../TestSignal';
import UserManagement from '../UserManagement';
import PrivateRoute from '../Layout/PrivateRoute/PrivateRoute';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from "../../redux/store";
import getRole from '../../utils/getRole';
import TestExchange from '../TestExchange';
import SummeryPostion from '../SummeryPosition/index';
import HistoryTradeBingx from '../HistoryTrade';

type RouteItem = {
  component: ReactElement
  route: string;
  roles: string[]
};

const routes: RouteItem[] = [
  {
    component: <Dashboard />,
    roles: ['admin', 'superadmin'],
    route: "/Dashboard",
  },
   
  {
    component: <Dashboard />,
    roles: ['admin', 'superadmin'],
    route: "/",
  },
  {
    component: <RealSnapshot />,
    roles: ['admin', 'superadmin'],
    route: "/RealSnapshot",
  },
  {
    component: <SnapshotHistory />,
    roles: ['admin', 'superadmin'],
    route: "/SnapshotHistory",
  },
  {
    component: <Settings />,
    roles: ['superadmin'],
    route: "/Settings",
  },
  {
    component: <Demo />,
    roles: ['admin', 'superadmin'],
    route: "/Demo",
  },
  {
    component: <BingxSettings />,
    roles: ['admin', 'superadmin'],
    route: "/BingxSettings",
  },
  {
    component: <SnapShotDetails />,
    roles: ['superadmin'],
    route: "/SnapshotDetails/:id",
  },
  {
    component: <TestExchange />,
    route: "/Signal",
    roles: ['superadmin']
  }, {
    component: <UserManagement />,
    route: "/UserManagement",
    roles: ['superadmin']

  },
  {
    component: <SummeryPostion />,
    route: "/summery",
    roles: ['superadmin']
  },
  {
    component: <HistoryTradeBingx />,
    route: "/history-trade-bing",
    roles: ['superadmin']
  },
];

// <Route
//   key={index}
//   Component={() => routeItem.component}
//   path={routeItem.route}
// />
const Content = () => {
  // const user = useSelector((state: RootState) => state.user);
  const user = getRole()

  console.log("user---------", user);

  return (
    <div className='flex-col flex-1 w-full lg:w-[calc(100vw - 350px)]  flex min-h-screen p-4'>
      <Header />
      <Routes>
        {routes.map((routeItem, index) => {
          if (!user || !routeItem.roles.includes(user)) {
            return null;
          } else {

            return <Route
              key={index}
              Component={() => routeItem.component}
              path={routeItem.route}
            />
          }
        }
      )}
      </Routes>
    </div>
  )
}


export default Content


