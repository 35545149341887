import React from 'react';
import { StatusRecordType } from '../useDemo'; // Replace 'path-to-StatusRecordType' with the correct path to the file where StatusRecordType is defined.
import TimeStraTegyTable from './TimeStrategyTable';
import getRole from '../../../utils/getRole';
interface Props {
    statusRecord: StatusRecordType[] | StatusRecordType;
    signalFront: any
    ethsignalFront: any
}
interface PriceQType {
    price: number[]; // Assuming price is an array of numbers
}

function OrderRecentTable({ statusRecord, signalFront, ethsignalFront }: Props) {
    const user = getRole()

    let priceQ: PriceQType[] = [];

    if (Array.isArray(statusRecord)) {
        priceQ = statusRecord.map(record => ({ price: record.priceQueue }));
    } else if (typeof statusRecord === 'object') {
        priceQ = [{ price: statusRecord.priceQueue }];
    }

    // if (priceQ.length === 0) {
    //     // Render a message or placeholder if statusRecord is empty
    //     return <div>No data available</div>;
    // }

    // statusRecord.priceQueue is an array  
    let statusArray: StatusRecordType[] = [];

    if (Array.isArray(statusRecord)) {
        statusArray = statusRecord;
    } else if (typeof statusRecord === 'object') {
        statusArray = [statusRecord];
    }

    // if (statusArray.length === 0) {
    //     // Render a message or placeholder if statusRecord is empty
    //     return <div>No data available</div>;
    // }
    return (
        <div className="overflow-x-auto flex flex-col gap-y-6   w-full py-4 ">

            {statusArray?.map((row: StatusRecordType, index: number) => (
                <div className="flex justify-between">
                <h1 className='text-yellow-500 text-2xl flex align-bottom '>
                    <span className='text-white ml-5'>  قیمت لحظه ای : </span>
                    {row.price}
                </h1>
                <h1 className='text-2xl flex align-bottom gap-5 '> 
                 <span className='text-yellow-500 '><span className='text-yellow-500'>$</span>{row && row.balance &&row?.balance}</span><span className='text-white'> Balance </span>
                </h1>
                </div>
            ))}
                
            <div className="shadow-lg   border border-white/15 overflow-x-auto w-full rounded-2xl">
                <table className=" table   overflow-hidden ">
                    <thead className="bg-sec text-white">
                        <tr>
                            <th className='!rounded-tr-2xl'>#</th>
                            <th>قیمت شروع</th>
                            <th>قیمت verify</th>
                            <th>نوع</th>
                            <th>وضعیت (order)</th>
                            <th>قیمت (Bing)</th>
                            {user === "superadmin" && (
                                <><th>g-volume</th>
                                    <th>گام</th>
                                    <th>حجم (ask)</th>
                                    <th className='!rounded-tl-2xl'>حجم (bids)</th>
                                </>)}
                        </tr>
                    </thead>
                    <tbody className='text-white bg-sec '>
                        {statusArray.map((row: StatusRecordType, index: number) => (
                            <tr key={index} className={`text-lg ${index % 2 === 0 ? "bg-primary/60" : ""} `}>
                                <td className={`${statusArray?.length - 1 === index ? "!rounded-br-2xl" : ""}`}>{index + 1}</td>
                                <td>{row.entryPrice}</td>
                                <td>{row.tolerancePrice}</td>
                                <td>{row.type}</td>
                                <td>{row.orderStatus}</td>
                                <td>{row.bingBTCPrice}</td>
                                {user === "superadmin" && (
                                    <>
                                        <td>{row.status}</td>
                                        <td>{row.step}</td>
                                        <td>{row.asksOneCent}</td>
                                        <td className={`${statusArray?.length - 1 === index ? "!rounded-bl-2xl" : ""}`}>{row.bidsOneCent}</td>
                                    </>)}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <div className="shadow-lg overflow-hidden  border border-white/15 w-full rounded-2xl">

            <table className="table">
                <thead className="bg-green-900 text-gray-200">
                    <tr>
                        <th className='!rounded-tr-2xl'>#</th>
                        <th  >قیمت شروع</th>
                        <th>نوع</th>
                        <th>وضعیت (order)</th>
                        <th className='!rounded-tl-2xl'>حجم</th>
                    </tr>
                </thead>
                <tbody className="text-gray-700 bg-white/80 ">
                </tbody>
            </table>
            </div> */}

            <div className='w-full flex gap-x-2 gap-6 flex-col lg:flex-row lg:justify-between'>
                {user === "superadmin" && (

                    <div className='w-full lg:w-11/12 flex flex-col lg:flex-row lg:justify-between gap-x-2 gap-6'>
                        <div className='lg:w-1/2 w-full'>
                            <TimeStraTegyTable signalFront={signalFront} title="BTC" />
                        </div>
                        <div className='lg:w-1/2 w-full'>
                            <TimeStraTegyTable signalFront={ethsignalFront} title="ETH" />
                        </div>
                    </div>)}
                <div className="shadow-lg   border border-white/15 w-1.5/12 overflow-x-auto h-full rounded-2xl">
                    <table className="h-full overflow-hidden table">
                        <thead className="bg-sec text-white">
                            <tr >
                                <th>#</th>
                                <th >قیمت</th>
                            </tr>
                        </thead>
                        <tbody className="text-white text-xl  bg-sec">
                            {priceQ.map((item, index) => (
                                item.price.map((price, idx) => (
                                    <tr key={index + '-' + idx} className={` text-sm ${idx % 2 === 0 ? "bg-primary/60" : ""} `}>
                                        <td>{idx + 1}</td>

                                        <td>{price}</td>
                                    </tr>

                                ))
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrderRecentTable;
