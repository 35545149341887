import React from 'react'
import { socketClient } from "../../Socket";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { centralNodesocketClient } from '../../Socket/centralNodeSocket';


function useSummeryPostion() {
  const [summeryPositonState,setSummeryPositonState] = useState<any>()
  const [summeryStatus,SetsummeryStatus] = useState<any>()
  useEffect(() => {
        socketClient.emit("join-page", "summeryPosition");

        socketClient.on("SummeryPosition", (data) => {
            // console.log("ramy data ", data)
            setSummeryPositonState(data)
        })
        centralNodesocketClient.emit("join-room", "summery-table");
        centralNodesocketClient.on("summery-table-res", (data) => {
            // console.log("summery data ", data)
            SetsummeryStatus(data)
        })
      
    },[])
       

  return (
    {summeryPositonState, summeryStatus}
  )
}

export default useSummeryPostion