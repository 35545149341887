import React from 'react'

function BingxTable({bingxData}:any) {

  return (
    <div className="overflow-x-auto flex flex-col gap-y-6   w-full py-4 ">
            <h1 className="text-right text-xl text-white">Bingx order</h1>
            <div className="shadow-lg   border border-white/15 overflow-x-auto w-full rounded-2xl">

         <table className="  w-full   table ">
                    <thead className='bg-sec text-white'>
                        <tr className="rounded-t-xl">
                        <th className="py-3"> #</th>
                        <th className="py-3">قیمت شروع </th>

                        <th className="py-3"> نوع </th>
                        <th className="py-3"> وضعیت (order)</th>
                        <th className="py-3"> حجم</th>
                            <th className="py-3"> تعداد  take</th>

                        </tr>
                    </thead>
                    <tbody className=" bg-sec  text-white text-lg">
                        <td className="py-3">1</td>
                       <td>
                        {bingxData&&bingxData?.entryPrice}
                       </td>
                       <td>
                       {bingxData&&bingxData?.positionSide}
                       </td>
                       <td>
                       {bingxData&&bingxData?.orderStatus}
                       </td>
                       <td>
                        {bingxData&&Number(bingxData?.qty).toFixed(3)}
                       </td>
                       <td>
                        {bingxData&&Number(bingxData?.takeCount)}
                       </td>
                    </tbody>
                </table>
                </div>
    </div>
  )
}

export default BingxTable