import { constants } from "../constants/constants";

const baseApi = constants.apiUrl;
// const baseApi = "http://localhost:4000";

const Api = {
  Login: `login`,
  createSnapshotHistory: `${baseApi}api/insert-snapshot`,
  getSettings: `get-setting`,
  getUsers: `get-users`,
  updateMaxMoney: `update-max-money`,
  getSettingsSecond: `get-setting-second`,
  updateSettings: `update-setting`,
  updateSettingsSecond: `update-setting-second`,
  updateSettingsThird: `update-setting-third`,
  getSnapshotHistories: `${baseApi}api/get-snapshot-history`,
  getSnapshotDetails: `${baseApi}api/get-snapshot-details`,
  deleteSnapshotHistory: `${baseApi}api/remove-snapshot`,
  deleteFuturesHistory: `delete-futures-history`,
  getFuturesHistory: `get-futures-history`,
  getBingSetting: `${baseApi}get-bing-setting`,
  updateBingSetting: `${baseApi}update-bing-setting`,
  tradeHistory: `${baseApi}bing-trade-history`,

};

export { Api, baseApi };