

import React from 'react'
import useSummeryPostion from './useSummeryPostion'

function SummeryPostion() {
  const {summeryPositonState, summeryStatus }:any = useSummeryPostion();
  
  const demo:any= summeryPositonState?.positionModel;

const bingx:any= summeryPositonState?.bingPosition;
const take_pro:any= summeryPositonState?.take_profit
const SocketIsePending :any  =summeryPositonState?.SocketIsePending
// console.log("take_pro",take_pro)
// console.log("bingx",bingx)
// console.log("demo",demo)
// console.log("SocketIsePending",SocketIsePending)

  return (
    <div className="overflow-x-auto flex flex-col gap-y-6   w-full py-4">
          <div className="shadow-lg  px-20  border border-white/15 overflow-x-auto w-full rounded-xl  ">
 

          <h1 className="text-right text-lg text-white mt-20 mb-5 ">وضعیت بینگ ایکس</h1>

          <table className=" table   ">
                    <thead className='  text-white text-lg  '>
                        <tr className="rounded-t-xl  bg-[#080723] ">
                        <th className="py-3 text-center" > #</th>
                        <th className="py-3 text-center" > binxQuantity</th>
                        <th className="py-3 text-center" > entryPrice</th>

                        <th className="py-3 text-center" > isOpen</th>

                        <th className="py-3 text-center" > isPending</th>
                        <th className="py-3 text-center" > waiting</th>

                        <th className="py-3 text-yellow-400"> orderStatus</th>
                        <th className="py-3 text-center text-red-600"  > positionSide</th>
                      
                        </tr>
                    </thead>
                    <tbody className=" text-white text-lg ">
                      <tr className='  bg-[#414060] '>
                        <td className="py-3">1</td>
                       <td className='text-center'>
                        {bingx&&bingx?.binxQuantity}
                       </td>
                       <td  className='text-center'>
                       {bingx && bingx.entryPrice !== undefined && (bingx.entryPrice )}
                       </td>
                       <td  className='text-center'>
                       {bingx && bingx.isOpen !== undefined && (bingx.isOpen ? "true" : "false")}
                       </td>
                       <td  className='text-center  '>
                       {bingx && bingx.isPending !== undefined && (bingx.isPending? "true" : "false")}
                       </td>
                       <td  className='text-center '>
                       {bingx && bingx.waiting !== undefined && (bingx.waiting ? "true" : "false")}
                       </td>
                       <td  className='text-center '>
                       {bingx && bingx.orderStatus !== undefined && (bingx.orderStatus)}

                       </td>
                       
                       <td  className='text-center'>
                       {bingx && bingx.positionSide !== undefined && (bingx.positionSide)}
                       </td>
                     
                       </tr>
                    </tbody>
                    <div className='text-white text-lg flex flex-col'><h2>
      </h2></div>
                </table>



                <h1 className="text-right text-lg text-white text-purple-500 my-5 ">وضعیت دمو </h1>
                <table className="  w-full   table  ">
                  
                    <thead className='bg-[#080723]  text-white text-lg'>
                        <tr className="rounded-t-xl">
                        <th className="py-3 text-center" > #</th>
                        <th className="py-3 text-center" > isOpen</th>
                        <th className="py-3 text-center text-yellow-400" > orderStatus</th>
                        <th className="py-3 text-center " > type</th>
                      
                      

                        </tr>
                    </thead>
                    <tbody className=" bg-[#414060]  text-white text-lg">
                        <td className="py-3 text-center">1</td>
                      
                       <td  className='text-center'>
                       {demo && demo.isOpen !== undefined && (demo.isOpen ? "true" : "false")}

                       </td>
     
                       <td  className='text-center'>
                       {demo && demo.orderStatus !== undefined && (demo.orderStatus)}
                       </td>
                       <td  className='text-center  '>
                       {demo && demo.type !== undefined && (demo.type )}
                       </td>
                       
                    </tbody>
                </table>

                <h1 className="text-right text-lg text-white  my-5">تیک پروفیت</h1>

                <table className="w-full table">
      <thead className='bg-sec text-white text-lg'>
        <tr className="rounded-t-xl bg-[#080723] ">
          <th className="py-3 text-center">#</th>
          <th className="py-3 text-center">makerPrice</th>
          <th className="py-3 text-center">percent</th>
        </tr>
      </thead>
      <tbody className=" text-white text-lg">
        {take_pro&&Object.keys(take_pro)?.map((key, index) => (
          <tr  className='bg-[#414060] ' key={index}>
            
            <td className="py-3 text-center">{index}</td>
            <td className="py-3 text-center">{take_pro[key]?.makerPrice}</td>
            <td className="py-3 text-center">{take_pro[key]?.percent}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <h1 className="text-right text-lg text-white my-5">خلاصه شروط</h1>

    <table className="w-full table  ">
    <thead className=' text-white text-base bg-[#414060] '>
    <tr className="rounded-t-xl bg-[#080723] text-center ">
    <td>binance-BTC-all-order </td>
    <td>binance-ETH-pish-10 </td>
    <td>binance-ETH-pish-20</td>
    <td>bybit-BTC-all-order</td>
        </tr>  
      
      </thead>
      
      <tbody className="  text-white text-sm ">
      <tr className="py-3 text-center  ">
      <td>{summeryStatus && summeryStatus["binance-BTC-all-order"][0]} <span className='text-blue-400'>env :</span> {summeryStatus&& summeryStatus["binance-BTC-all-order"][1]} </td>
      <td>{summeryStatus && summeryStatus["binance-ETH-pish-10"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["binance-ETH-pish-10"][1]} </td>
      <td>{summeryStatus && summeryStatus["binance-ETH-pish-20"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["binance-ETH-pish-20"][1]} </td>
      <td>{summeryStatus && summeryStatus["bybit-BTC-all-order"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["bybit-BTC-all-order"][1]} </td>

      </tr>
  

      </tbody>

                        
       <thead  className=' text-white text-sm  bg-[#414060]'>
                        <tr className="rounded-t-xl bg-[#080723] text-center ">
                        <td>condition7 </td>
                        <td>condition8 </td>
                        <td>gvolume </td>
                        <td>pish ha</td>
        </tr>  
    </thead>
    <tbody className="  text-white text-sm">
    <tr className="py-3 text-center ">
      <td className='text-center'>{summeryStatus && summeryStatus["condition7"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["condition7"][1]} </td>
      <td className='text-center'>{summeryStatus && summeryStatus["condition8"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["condition8"][1]} </td>
      <td className='text-center'>{summeryStatus && summeryStatus["gvolume"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["gvolume"][1]} </td>
      <td className='text-center'>{summeryStatus && summeryStatus["pish ha"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["pish ha"][1]} </td>
      </tr>
      </tbody>
      <thead  className=' text-white text-sm bg-[#414060] '>
                        <tr className="rounded-t-xl bg-[#080723] text-center ">
                        <td >signal-table1 </td>
                        <td>signal-table2 </td>
                        <td className=''>Result </td>

        </tr>  
    </thead>
    <tbody className="  text-white text-sm">
    <tr className="py-3  text-center">
    <td className='text-center'>{summeryStatus && summeryStatus["signal-table1"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["signal-table1"][1]} </td>
    <td className='text-center'> {summeryStatus && summeryStatus["signal-table2"][0]} <span className='text-blue-400'>env :</span> {summeryStatus && summeryStatus["signal-table2"][1]} </td>
    <td  className='text-red-600 text-center'>{summeryStatus && summeryStatus.Result}  </td>
      </tr>
      </tbody>
      </table>
      <table className="w-full table mb-20  ">
      <thead className=' text-white text-base bg-[#414060] '>
      </thead>
      <tr className="rounded-t-xl bg-[#080723] text-center ">
      <td >دیتای منوآل </td>
      <td >دیتای بینگ </td>
                   

        </tr>  
        
        <tbody className=" bg-[#414060]  text-white text-xl">
        <tr className="py-3  text-center">

        <td className='text-center'> {bingx && bingx.statusEmiiter_1 !== undefined && (bingx.statusEmiiter_1 ? "true" : "false")}  </td>
        <td className='text-center'> {bingx && bingx.statusEmiiter_2 !== undefined && (bingx.statusEmiiter_2 ? "true" : "false")}  </td>

      </tr>
      </tbody>
      </table>



                </div> 
    </div>
  )
}

export default SummeryPostion

