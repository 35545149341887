import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/slices/userSlice";
import { Api } from "../../Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function useHistoryTradeBingx() {
const [tradeHistoryState, setTradeHistoryState] = useState <any>();
async function fetchtradeHistory() {
    try {
        const response = await axios.get(Api.tradeHistory);
        if(response.data.code==200){
        console.log("response",response.data.data)
        setTradeHistoryState(response.data.data);
        toast.success("با موفقیت بروزرسانی شد ");

        }else{
            toast.error(" خطا در بروزرسانی ");

        }
    } catch (e) {
        console.log("******", e);
        toast.error("مشکلی پیش آمده");

    }
}
useEffect(() => {
    fetchtradeHistory();
}, []);
return { tradeHistoryState };

}
export default useHistoryTradeBingx;
