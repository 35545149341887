import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { constants } from "../../../constants/constants";

const Header = () => {

  return (
    <div className=" bg-transparent  w-full h-28 transition-all duration-300 flex flex-col">
      <div className=" flex   justify-end  w-full h-full pl-10">
        <a target="_blank" href={`${constants.apiUrl}api/socket/usid/1317`}>
        <img src="./images/logorobo-2.png" alt="logorobo" className="w-16 h-20" />
        </a>
      </div>
    </div>
  );
};

export default Header;
