import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../../Api";
import useAxios from "../../Hooks/request/hooks/useAxios";
import useReactHookFormWrapper from "../sections/Form/ReactHookFormWrapper/hooks/useReactHookFormWrapper";
import { toast } from "react-toastify";
import { OpenOrderField, NewOpenOrderField, TreadDone, ChangeStatusOffset, OpenPositionField, VerifyAfterZeroField, VerifyField, PriceDifferenceOrderField, LeverageField, StopLossField,withDemo } from "./LabelAndNameFields"
import { OpenOrderFieldSecond, NewOpenOrderFieldSecond, TreadDoneSecond, ChangeStatusOffsetSecond, OpenPositionFieldSecond, VerifyAfterZeroFieldSecond, VerifyFieldSecond, PriceDifferenceOrderFieldSecond, LeverageFieldSecond, StopLossFieldSecond } from "./LabelAndNameFieldsSecond"
import { activityToggleRowName } from "./Fields/ToggleButtonField";
const useSettings = () => {
  const [data, setData] = useState<any>();
  const [getSettingsResponse, getSettings] = useAxios()
  const [getSettingsSecondResponse, getSettingsSecond] = useAxios()
  const [updateSettingsResponse, updateSettings] = useAxios()
  const [updateSettingsSecondResponse, updateSettingsSecond] = useAxios()


  // first rest api

  async function fetchSettingsHandler() {
    const url = Api.getSettings;
    const res = await getSettings({
      url,
      method: "GET",
    }).then((res) => {
      const dataServer = res.data
      setData(dataServer);
      setValue(PriceDifferenceOrderField.name, dataServer.makerPrice);
      setValue(StopLossField.name, dataServer.stopPrice);
      setValue(VerifyField.name, dataServer.verifyTolerance);
      // setValue(LeverageField.name, dataServer.leverage);
      setValue(OpenOrderField.name, dataServer.openOrderStep);
      setValue(NewOpenOrderField.name, dataServer.newOpenOrderStep);
      setValue(ChangeStatusOffset.name, dataServer.changeStatusOffset);
      setValue(OpenPositionField.name, dataServer.closePositionStep);
      setValue(VerifyAfterZeroField.name, dataServer.verifyToleranceAfterZero);
      setValue(TreadDone.name, dataServer.trade);
      setValue(withDemo.name, dataServer.withDemo);

      
      console.log("res first : ", dataServer);

    });

  }

  useEffect(() => {
    fetchSettingsHandler().then();
  }, [updateSettingsResponse]);
  const {
    formMethods,
    onSubmit,
  } = useReactHookFormWrapper({
    onSubmitHandler,
  });

  const { setValue, watch, reset } = formMethods;
  function onSubmitHandler(data: any) {
    const dataFinall = {
      makerPrice: Number(data[PriceDifferenceOrderField.name]),
      stopPrice: Number(data[StopLossField.name]),
      trade: data[TreadDone.name],
      verifyTolerance: Number(data[VerifyField.name]),
      // leverage: Number(data[LeverageField.name]),
      openOrderStep: Number(data[OpenOrderField.name]),
      newOpenOrderStep: Number(data[NewOpenOrderField.name]),
      closePositionStep: Number(data[OpenPositionField.name]),
      verifyToleranceAfterZero: Number(data[VerifyAfterZeroField.name]),
      changeStatusOffset: Number(data[ChangeStatusOffset.name]),
      withDemo:Number(data[withDemo.name]),

    };

    console.log("withdmoo",data)

    const url = Api.updateSettings;
    updateSettings({ url, method: "PUT", data: dataFinall }).then(
      (res) => {
        toast.success("با موفقیت بروزرسانی شد");
      }
    );
  }





  // ==================================================================================================

  // second rest api

  useEffect(() => {
    fetchSettingsHandlerSecondForm().then();
  }, [updateSettingsSecondResponse]);

  async function fetchSettingsHandlerSecondForm() {
    const url = Api.getSettingsSecond;

    const res = await getSettingsSecond({
      url,
      method: "GET",
    }).then((res) => {
      const dataServer = res.data
      setValueSecond(PriceDifferenceOrderFieldSecond.name, dataServer.makerPrice);
      setValueSecond(StopLossFieldSecond.name, dataServer.stopPrice);
      setValueSecond(VerifyFieldSecond.name, dataServer.verifyTolerance);
      // setValue(LeverageField.name, dataServer.leverage);
      setValueSecond(OpenOrderFieldSecond.name, dataServer.openOrderStep);
      setValueSecond(NewOpenOrderFieldSecond.name, dataServer.newOpenOrderStep);
      setValueSecond(ChangeStatusOffsetSecond.name, dataServer.changeStatusOffset);
      setValueSecond(OpenPositionFieldSecond.name, dataServer.closePositionStep);
      setValueSecond(VerifyAfterZeroFieldSecond.name, dataServer.verifyToleranceAfterZero);
      setValueSecond(TreadDoneSecond.name, dataServer.trade);
      console.log("res  : ", dataServer);

    });

  }

  const {
    formMethods: formMethodsSecondForm,
    onSubmit: onSubmitSecondForm,
  } = useReactHookFormWrapper({
    onSubmitHandler: onSubmitHandlerForSecondForm,
  });
  const { setValue: setValueSecond } = formMethodsSecondForm;

  function onSubmitHandlerForSecondForm(data: any) {
    const dataFinall = {
      makerPrice: Number(data[PriceDifferenceOrderFieldSecond.name]),
      stopPrice: Number(data[StopLossFieldSecond.name]),
      trade: data[TreadDone.name],
      verifyTolerance: Number(data[VerifyFieldSecond.name]),
      // leverage: Number(data[LeverageField.name]),
      openOrderStep: Number(data[OpenOrderFieldSecond.name]),
      newOpenOrderStep: Number(data[NewOpenOrderFieldSecond.name]),
      closePositionStep: Number(data[OpenPositionFieldSecond.name]),
      verifyToleranceAfterZero: Number(data[VerifyAfterZeroFieldSecond.name]),
      changeStatusOffset: Number(data[ChangeStatusOffsetSecond.name])

    };

    const url = Api.updateSettingsSecond;
    updateSettingsSecond({ url, method: "PUT", data: dataFinall }).then(
      (res) => {
        toast.success("با موفقیت بروزرسانی شد");
      }
    );
  }




  return {
    formMethods,
    onSubmit,
    formMethodsSecondForm,
    onSubmitSecondForm,
  };
};
export default useSettings;
