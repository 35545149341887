import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../../Api";
import useAxios from "../../Hooks/request/hooks/useAxios";
import useReactHookFormWrapper from "../sections/Form/ReactHookFormWrapper/hooks/useReactHookFormWrapper";
import { toast } from "react-toastify";
// import { OpenOrderField, NewOpenOrderField, TreadDone, ChangeStatusOffset, OpenPositionField, VerifyAfterZeroField, VerifyField, PriceDifferenceOrderField, LeverageField, StopLossField } from "./LabelAndNameFields"
import { MaxMoney } from "./LableUsers";

const useSettings = () => {
  const [data, setData] = useState<any>();
  const [userCurrent, setUserCurrent] = useState<any>();
  const [getUsersResponse, getUsers] = useAxios()
  const [updateMaxMoneyResponse, updateMaxMoney] = useAxios()


  // first rest api

  async function GetUsersHandler() {
    const url = Api.getUsers;

    const res = await getUsers({
      url,
      method: "GET",
    }).then((res) => {
    setData(res)
    });

  }

  useEffect(() => {
    GetUsersHandler().then();
  }, [updateMaxMoneyResponse]);
  const {
    formMethods,
    onSubmit,
  } = useReactHookFormWrapper({
    onSubmitHandler,
  });

  const { setValue, watch, reset } = formMethods;
  function onSubmitHandler(data: any) {
    console.log("data",data);
    
    const dataFinall = {
        maxMoney: Number(data[MaxMoney.name]),
        id : userCurrent

    };

    const url = Api.updateMaxMoney;
    updateMaxMoney({ url, method: "POST", data: dataFinall }).then(
      (res) => {
        toast.success("با موفقیت بروزرسانی شد");
      }
    );
  }


  return {
    formMethods,
    onSubmit,
    data,
    setUserCurrent
  };
};
export default useSettings;
