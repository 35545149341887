import moment from "moment";
import React, { useEffect, useState } from "react";
import { Types } from "../../../type/types";

interface SnapshotTableProps {
    children: Types["children"];
    data: any[];
    title: string;
}
interface DataItem {
    entryPrice: string;
    typee: string;
    profit: string;
    endPrice: number;
    createdAt: string;
}
const RecentOrderTable = ({ data, title, children }: SnapshotTableProps) => {
    const [sortedData, setSortedData] = useState<any[]>([]);

    useEffect(() => {
        if (Array.isArray(data)) {
            const sorted = [...data].sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
            setSortedData(sorted);
        }
    }, [data]);

    // تابع برای محاسبه مجموع سود همه آیتمها
    function calculateTotalProfit(data: DataItem[]): number {
        if (!data) {
            return 0
        } else {
            let totalProfit = 0;
            data.forEach(item => {
                totalProfit += parseFloat(item.profit);
            });
            return totalProfit;
        }
    }

    const totalProfit = calculateTotalProfit(data);

    // if (!Array.isArray(data)) {
    //     return <div>No data available</div>;
    // }

    return (
        <div className="flex flex-col gap-4 w-full py-4 ">
            <div className="w-full">
                <div className="w-full flex gap-x-2 justify-start" >
                    <span className="text-lg text-white"> جمع سود ها :</span>
                    <span className="text-yellow-500 text-lg">{totalProfit.toFixed(2)}</span>
                </div>
            </div>
            <div className="flex justify-between">
                <span className="text-lg self-center font-semibold text-white">{title}</span>
                <div>{children}</div>
            </div>
            <div className="shadow-lg overflow-x-auto border border-white/15  w-full   rounded-2xl">
                <table className="  w-full   table">
                    <thead className='bg-sec text-white'>
                        <tr className="rounded-t-xl">
                            <th className="py-3"> #</th>
                            <th className="py-3">قیمت شروع</th>
                            <th className="py-3">قیمت پایان</th>
                            <th className="py-3">سود به دلار</th>
                            <th className="py-3">نوع</th>
                            <th className="py-3">زمان پایان معامله</th>
                        </tr>
                    </thead>
                    <tbody className=" bg-sec ">
                        {sortedData.map((item, index) => (
                            <tr key={index} className={`text-lg text-white ${index % 2 === 0 ? "bg-primary/60" : ""} `}>
                                <td className="">{index + 1}</td>
                                <td>{item.entryPrice}</td>
                                <td>{item.endPrice}</td>
                                <td>{item.profit}</td>
                                <td style={{ color: item.typee === "LONG" ? "green" : "#d31800" }}>{item.typee}</td>
                                <td>{moment(item.createdAt).format("llll")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RecentOrderTable;
