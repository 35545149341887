import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tokenToString } from "typescript";

export interface UserSliceType {
  name: string;
  token: string;
  counter: number;
  signalStatus:string,
  role:string | "admin"|"superadmin"
}
const token = localStorage.getItem("token") || "";

const initialState: UserSliceType = {
  name: "user1",
  counter: 0,
  token: token,
  signalStatus:'',
  role:"admin"
};

const userSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    changeName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    decrement: (state) => {
      state.counter -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.counter += action.payload;
    },
    setUserInfo: (
      state,
      action: PayloadAction<{
        name: string;
        token: string;
        role: string
      }>
    ) => {
      state.name = action.payload.name;
      state.token = action.payload.token;
      state.role = action.payload.role;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setSignalStatus: (state, action: PayloadAction<string>) => {
      state.signalStatus = action.payload;
    },
  },
});

export const { changeName, decrement, incrementByAmount, setUserInfo,setSignalStatus ,setToken} =
  userSlice.actions;

export default userSlice.reducer;
