import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import getRole from "../../utils/getRole";
import useLogout from "../../utils/useLogout";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  type MenuItem = {
    title: string;
    onClick: () => void;
    url: string
    roles: string[]

  };

  const menus: MenuItem[] = [
    {
      title: "داشبورد",
      onClick: () => {
        navigate("/Dashboard");
      },
      url: "/Dashboard",
      roles: ['admin', 'superadmin']

    },
    {
      title: "اسنپ شات لحظه ای",
      onClick: () => {
        navigate("/RealSnapshot");
      },
      url: "/RealSnapshot",
      roles: ['superadmin']

    },
    {
      title: "اسنپ شات",
      onClick: () => {
        navigate("/SnapshotHistory");
      },
      url: "/SnapshotHistory",
      roles: ['superadmin']

    }, {
      title: "دمو",
      onClick: () => {
        navigate("/Demo");
      },
      url: "/Demo",
      roles: ['admin', 'superadmin']

    },
    {
      title: "تنظیمات صرافی ",
      onClick: () => {
        navigate("/BingxSettings");
      },
      url: "/BingxSettings",
      roles: ['admin', 'superadmin']

    },
    {
      title: "تنظیمات",
      onClick: () => {
        navigate("/Settings");
      },
      url: "/Settings",
      roles: ['superadmin']

    },
    {
      title: "سیگنال",
      onClick: () => {
        navigate("/Signal");
      },
      url: "/Signal",
      roles: ['superadmin']

    },
    {
      title: 'مدیریت  کاربران',
      onClick: () => {

        navigate("/UserManagement");
      },
      url: "/UserManagement",
      roles: ['superadmin']

    },
    {
      title: 'تاریخچه بینگ',
      onClick: () => {

        navigate("/history-trade-bing");
      },
      url: "/history-trade-bing",
      roles: ['superadmin']

    },
    {
      title: 'Summery',
      onClick: () => {

        navigate("/summery");
      },
      url: "/Summery",
      roles: ['superadmin']

    },
  ];
  // const user = useSelector((state: RootState) => state.user);
  // console.log("user", user);
  const user = getRole()
  const logoutHook = useLogout();
  const handleLogout = async () => {
    logoutHook();
    navigate(0);

  }


  return (
    <>
      <div
        className={clsx(
          " bg-primary absolute rounded-tl-3xl z-10  lg:relative right-0 top-0 min-h-screen transition-all duration-300 flex flex-col",
          {
            "w-[270px] lg:w-[350px]": isMenuOpen,
            "w-0": !isMenuOpen,
          }
        )}
      >
        <div className="p-4">
          <IoMenu
            className="text-4xl text-white mr-auto cursor-pointer "
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>
        <div className="flex flex-col justify-around h-screen">
          <div
            className={clsx("flex flex-col gap-6 h-10/12", {
              flex: isMenuOpen,
              hidden: !isMenuOpen,
            })}
          >
            {menus.map((menuItem, index) => {
              if (!user || !menuItem.roles.includes(user)) {
                return <></>;
              } else {

                return <a key={index} href={menuItem.url} className="text-white  ">
                  {menuItem.title}
                </a>
              }
            })}

          </div>
          <div className="  h-2/12 e-full flex justify-center">
            <div onClick={handleLogout} className={`text-white cursor-pointer border-2 bg-danger/40 border-danger px-3  rounded-full py-2  w-3/4 ${isMenuOpen ? "block" : "hidden"}`}>
              خروج
            </div>
          </div>
        </div>
      </div></>
  );
};

export default Menu;
