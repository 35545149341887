import axios from "axios";
import { useEffect, useState } from "react";
import { Api } from "../../Api";
import { toast } from "react-toastify";
interface StepByStepTakeProfit {
    percent: number;
    makerPrice: number;
}

interface Settings {
    stopPrice: number;
    waitingTimeForCancel: number;
    price_rejection_maker_price: number;
    leverage: number;
    makerPrice: number;
    step_by_step_take_profit: StepByStepTakeProfit[];
    quantity:number;
    save_sood_status:boolean;
    timeForCancelOrder:number
}
const useSettings = () => {
    const [data, setData] = useState <any>();

    async function fetchSettingsHandler() {
        try {
            const response = await axios.get(Api.getBingSetting);
            console.log("response",response.data.data)
            setData(response.data.data);
        } catch (e) {
            console.log("******", e);
            window.alert("مشکلی پیش آمده");
        }
    }

    async function updateSettingsHandler(updatedData:any) {
        try {
            await axios.put(Api.updateBingSetting, updatedData);
            toast.success("با موفقیت بروزرسانی شد ");
            fetchSettingsHandler(); // Refresh the data
        } catch (e) {
            console.log("******", e);
            toast.error("مشکلی پیش آمده");
        }
    }

    useEffect(() => {
        fetchSettingsHandler();
    }, []);

    return { data, updateSettingsHandler };
};

export default useSettings;























































































































// import axios from "axios";
// import { useEffect, useState } from "react";
// import { Api } from "../../Api";
// import useAxios from "../../Hooks/request/hooks/useAxios";
// import useReactHookFormWrapper from "../sections/Form/ReactHookFormWrapper/hooks/useReactHookFormWrapper";
// import { toast } from "react-toastify";
// import {
//     PriceDifferenceOrderField, LeverageField, CanselTime, CloseLimit, StopLossField, CloseMarket, PriceDifferenceNumber,
//     PercentageNo, OpenOrderWithDemo, TreadDone
// } from "./LabelAndNameFields"
// import { activityToggleRowName } from "../Settings/Fields/ToggleButtonField";

// interface Row {
//     makerPrice: string;
//     percent: string;
// }
// const useSettings = () => {
//     const [data, setData] = useState<any>();
//     const [getSettingsResponse, getSettings] = useAxios()
//     const [updateSettingsResponse, updateSettings] = useAxios()
//     const [rows, setRows] = useState<Row[] | any[]>([]);
//     async function fetchSettingsHandler() {
//         const url = Api.getBingSetting;

//         const res = await getSettings({
//             url,
//             method: "GET",
//         }).then((res) => {
//             const dataServer = res.data
//             console.log(dataServer);
//             setValue(PriceDifferenceOrderField.name, dataServer.makerPrice);
//             setValue(StopLossField.name, dataServer.stopPrice);
//             setValue(CanselTime.name, dataServer.waitingTimeForCancel);
//             setValue(CloseLimit.name, dataServer.price_rejection_maker_price);
//             setValue(TreadDone.name, dataServer.trade);
//             setValue(LeverageField.name, dataServer.leverage);
//             setValue(CloseMarket.name, dataServer.close_po_with_close_all);
//             setValue(OpenOrderWithDemo.name, dataServer.openOrderWithDemo);
//             console.log(PriceDifferenceNumber)
//             if (dataServer.step_by_step_take_profit && dataServer.step_by_step_take_profit.length > 0) {
//                 dataServer.step_by_step_take_profit.map(({ row, index }: any) => {
//                     console.log("infun",row)
//                     setValue(`${PriceDifferenceNumber.name}_${index + 1}`, row.makerPrice);
//                     setValue(`${PercentageNo.name}_${index + 1}`, row.percent);
//                 })
//             }

//         });

//     }

//     useEffect(() => {
//         fetchSettingsHandler().then();
//     }, [updateSettingsResponse]);
//     const {
//         formMethods,
//         onSubmit,
//     } = useReactHookFormWrapper({
//         onSubmitHandler,
//     });

//     const { setValue, watch, reset } = formMethods;
//     function onSubmitHandler(data: any) {
//         console.log("[...rows]", [...rows]);

//         const dataFinall = {
//             makerPrice: data[PriceDifferenceOrderField.name],
//             stopPrice: data[StopLossField.name],
//             trade: data[TreadDone.name],
//             leverage: Number(data[LeverageField.name]),
//             close_po_with_close_all: data[CloseMarket.name],
//             openOrderWithDemo: data[OpenOrderWithDemo.name],
//             step_by_step_take_profit: rows.map((row, i) => {
//                 return { percent: Number(data[`${PercentageNo.name}_${i + 1}`]), makerPrice: Number(data[`${PriceDifferenceNumber.name}_${i + 1}`]) }
//             }),
//             price_rejection_maker_price: data[CloseLimit.name],
//             waitingTimeForCancel: data[CanselTime.name]
//         };

//         const url = Api.updateBingSetting;
//         updateSettings({ url, method: "PUT", data: dataFinall }).then(
//             (res) => {
//                 console.log("reee", res);

//                 toast.success("با موفقیت اعمال شد");
//             }
//         );
//     }


//     const addRow = () => {
//         const newRow: Row = { percent: '', makerPrice: '' };
//         setRows([...rows, newRow]);
//     };

//     const removeRow = () => {
//         if (rows.length === 0) return; // Make sure there's at least one row
//         const updatedRows = [...rows];
//         updatedRows.pop(); // Remove the last row
//         setRows(updatedRows);
//     };

//     const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number, field: keyof Row) => {
//         const updatedRows = [...rows];
//         const index = updatedRows.findIndex(row => row.id === id);
//         updatedRows[index][field] = e.target.value;
//         setRows(updatedRows);
//     };




//     return {
//         formMethods,
//         onSubmit,
//         addRow,
//         removeRow,
//         handleInputChange,
//         rows
//     };
// };
// export default useSettings;
