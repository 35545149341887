
import React from 'react'
import Card from "../sections/Card";
import ReactHookFormWrapper from '../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper';
import NumberMaxField from "./NumberMaxField";
import useUserManagement from "./useUserManagement";
import { MaxMoney } from "./LableUsers";
import Button from '../sections//Button/Button';
import { log } from 'console';

const UserManagement = () => {
    const { data, formMethods, onSubmit, setUserCurrent } = useUserManagement()
    return (
        <Card title='مدیریت  کاربران' >
            <div className='py-4 grid grid-cols-3'>
                {data && data.users.map((item: any, i:number) => {
                    return <div key={i} className="w-full py-6 max-w-sm bg-sec-primary border border-gray-200 rounded-lg shadow ">

                        <div className="flex flex-col items-center pb-10">

                            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                {item.uname}
                            </h5>
                            <ReactHookFormWrapper
                                formMethods={formMethods}
                                onSubmit={(data) => {
                                    console.log("item._id",item._id);
                                    
                                    setUserCurrent(item._id);
                                    return onSubmit(data)
                                }

                                }
                                hiddenButton
                                className={`flex flex-col justify-start p-4  rounded-2xl w-full`}
                            >
                                <NumberMaxField nameFieldProps={MaxMoney.name} labelFieldProps={MaxMoney.label} notRequired={true} />
                                <div className="flex gap-2 pt-4  justify-start">
                                    <>
                                        <Button
                                            color="primary"
                                            size="full-width"
                                            type="submit"
                                            // onClick={() => {
                                            //     setUserCurrent(item._id);
                                            //     onSubmit()
                                            // }}
                                            className={{ extra: "px-4 py-2 rounded-lg" }}
                                        >
                                            اعمال تنظیمات
                                        </Button>
                                    </>
                                </div>
                            </ReactHookFormWrapper>
                        </div>
                    </div>

                })

                } </div>
        </Card>

    )
}

export default UserManagement