import io from "socket.io-client";
import { baseApi } from "../Api";
const socketClient = io(baseApi, { transports: ["websocket"],  reconnection: true,
reconnectionAttempts: Infinity,
reconnectionDelay: 1000,
reconnectionDelayMax: 5000, });

socketClient.on("connect", () => {
  console.log("join orderbook");
});

export { socketClient };