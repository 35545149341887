import React, { useState, useEffect } from 'react';
import useSettings from './useBingxSettings';

function BingxSettings() {
    const { data, updateSettingsHandler } = useSettings();
    console.log("ddddd", data);
    
    const [settings, setSettings] = useState(data);

    useEffect(() => {
        if (data) {
            setSettings(data);
        }
    }, [data]);

    const handleChange = (e:any) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? checked : value;
        setSettings({ ...settings, [name]: val });
    };

    const handleAddProfitStep = () => {
        setSettings({
            ...settings,
            step_by_step_take_profit: [
                ...settings.step_by_step_take_profit,
                { percent: 0, makerPrice: 0 },
            ],
        });
    };

    const handleRemoveProfitStep = (index:any) => {
        const updatedSteps = settings.step_by_step_take_profit.filter((_:any, i:any) => i !== index);
        setSettings({ ...settings, step_by_step_take_profit: updatedSteps });
    };

    const handleProfitStepChange = (index:any, field:any, value:any) => {
        const updatedSteps = settings.step_by_step_take_profit.map((step:any, i:any) =>
            i === index ? { ...step, [field]: value } : step
        );
        setSettings({ ...settings, step_by_step_take_profit: updatedSteps });
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        updateSettingsHandler(settings);
    };

    if (!settings) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col justify-start py-4 rounded-2xl w-full text-white">
            <div className="bg-white bg-opacity-5 p-10 rounded-lg">
                <h2 className="text-start text-xl mb-16">تنظیمات صرافی</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex gap-5 w-full mb-10">
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="stopPrice">اختلاف قیمت (stopLoss)</label>
                            <input
                                value={settings?.stopPrice}
                                name="stopPrice"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="waitingTimeForCancel">زمان برای کنسل اردر</label>
                            <input
                                value={settings?.waitingTimeForCancel}
                                name="waitingTimeForCancel"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                    </div>
                    <div className="flex gap-5 w-full mb-10">
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="price_rejection_maker_price">اختلاف قیمت (close-limit)</label>
                            <input
                                value={settings?.price_rejection_maker_price}
                                name="price_rejection_maker_price"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="leverage">leverage</label>
                            <input
                                value={settings?.leverage}
                                name="leverage"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                    </div>
                    <div className="flex gap-5 w-full mb-10">
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="makerPrice">اختلاف قیمت در لحظه ثبت اردر</label>
                            <input
                                value={settings?.makerPrice}
                                name="makerPrice"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                         <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="quantity">مبلغ</label>
                            <input
                                value={settings?.quantity}
                                name="quantity"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                        
                    </div>
                    
                    <div className="flex gap-5 w-full mb-10">
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="timeForCancelOrder">زمان برای کنسل اردر 2</label>
                            <input
                                value={settings?.timeForCancelOrder}
                                name="timeForCancelOrder"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="timeForCloseOrder">زمان برای کلوز اردر </label>
                            <input
                                value={settings?.timeForCloseOrder}
                                name="timeForCloseOrder"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                
                    </div>
                    <div className="flex gap-5 w-full mb-10">
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="save_sood_take">save profit in take</label>
                            <input
                                value={settings?.save_sood_take}
                                name="save_sood_take"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                        <div className="flex flex-col items-start gap-3 flex-1">
                            <label htmlFor="ekhtelaf_baraye_cancel">اختلاف برای کنسل اوپن</label>
                            <input
                                value={settings?.ekhtelaf_baraye_cancel}
                                name="ekhtelaf_baraye_cancel"
                                onChange={handleChange}
                                type="number"
                                className="bg-[#282743] w-full py-1.5 rounded-md px-3"
                            />
                        </div>
                
                    </div>
                    <div className="flex gap-5 w-full mb-10 flex-col text-white text-opacity-70">
                    <div className="flex items-start gap-3 flex-1">
        <input
            id="trade"
            name="trade"
            type="checkbox"
            className="h-5 w-5 appearance-none rounded border border-gray-400 checked:bg-[#7480FF] checked:border-transparent focus:outline-none"
            checked={settings.trade}
            onChange={handleChange}
        />
        <label htmlFor="trade">ترید انجام شود</label>
    </div>
                    <div className="flex items-start gap-3 flex-1">
        <input
            id="checkSyncSignal"
            name="checkSyncSignal"
            type="checkbox"
            className="h-5 w-5 appearance-none rounded border border-gray-400 checked:bg-[#7480FF] checked:border-transparent focus:outline-none"
            checked={settings.checkSyncSignal}
            onChange={handleChange}
        />
        <label htmlFor="checkSyncSignal">ترید پایدار</label>
    </div>

    <div className="flex items-start gap-3 flex-1">
        <input
            id="save_sood_status"
            name="save_sood_status"
            type="checkbox"
            className="h-5 w-5 appearance-none rounded border border-gray-400 checked:bg-[#7480FF] checked:border-transparent focus:outline-none"
            checked={settings.save_sood_status}
            onChange={handleChange}
        />
        <label htmlFor="save_sood_status">save sood</label>
    </div>
    <div className="flex items-start gap-3 flex-1">
        <input
            id="close_po_with_close_all"
            name="close_po_with_close_all"
            type="checkbox"
            className="h-5 w-5 appearance-none rounded border border-gray-400 checked:bg-[#7480FF] checked:border-transparent focus:outline-none"
            checked={settings.close_po_with_close_all}
            onChange={handleChange}
        />
        <label htmlFor="close_po_with_close_all">close market</label>
    </div>
    <div className="flex items-start gap-3 flex-1">
        <input
            id="openOrderWithDemo"
            name="openOrderWithDemo"
            type="checkbox"
            className="h-5 w-5 appearance-none rounded border border-gray-400 checked:bg-[#7480FF] checked:border-transparent focus:outline-none"
            checked={settings.openOrderWithDemo}
            onChange={handleChange}
        />
        <label htmlFor="openOrderWithDemo">باز شدن اردر همراه دمو</label>
    </div>
</div>

                    <div className="mb-10 flex flex-col items-start">
                        <label className='mb-5'>Step by Step Take Profit</label>
                        {settings?.step_by_step_take_profit.map((step:any, index:any) => (
                            <div key={index} className="flex gap-3 mb-3">
                                <input
                                    value={step.percent}
                                    onChange={(e) =>
                                        handleProfitStepChange(index, 'percent', e.target.value)
                                    }
                                    type="number"
                                    placeholder="Percent"
                                    className="bg-[#282743] py-1.5 rounded-md px-3"
                                />
                                <input
                                    value={step.makerPrice}
                                    onChange={(e) =>
                                        handleProfitStepChange(index, 'makerPrice', e.target.value)
                                    }
                                    type="number"
                                    placeholder="Maker Price"
                                    className="bg-[#282743] py-1.5 rounded-md px-3"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleRemoveProfitStep(index)}
                                    className="bg-[#7480FF] py-1.5 px-3 rounded-md text-white"
                                >
                                    حذف
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={handleAddProfitStep}
                            className="bg-[#7480FF] py-1.5 px-3 rounded-md text-white " 
                        >
                            افزودن
                        </button>
                    </div>
                    <button type="submit" className="bg-[#7480FF] py-1.5 px-3 rounded-lg text-white">
                        بروزرسانی
                    </button>
                </form>
            </div>
        </div>
    );
}

export default BingxSettings;





















// import React from 'react'
// import useBingxSettings from './useBingxSettings'
// import Card from "../sections/Card";
// import ReactHookFormWrapper from '../sections/Form/ReactHookFormWrapper/ReactHookFormWrapper';
// import NumberSettingsField from '../Settings/Fields/NumberSettingsField';
// import {
//     PriceDifferenceOrderField, PriceDifferenceNumber,
//     PercentageNo, CanselTime, CloseLimit, LeverageField, StopLossField, CloseMarket, OpenOrderWithDemo, TreadDone
// } from "./LabelAndNameFields"
// import Button from '../sections/Button/Button';
// import ToggleButtonField from '../Settings/Fields/ToggleButtonField';
// const BingxSettings = () => {
//     const { formMethods,
//         onSubmit, addRow,
//         removeRow, rows, handleInputChange } = useBingxSettings()
//         console.log("thisis row =>",rows )

//     return (
//         <Card title='تنظیمات صرافی'>
//             <ReactHookFormWrapper
//                 formMethods={formMethods}
//                 onSubmit={onSubmit}
//                 hiddenButton
//                 className={`flex flex-col justify-start py-4  rounded-2xl w-full`}
//             >
//                 <div className="flex flex-col">
//                     <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3">
//                         <NumberSettingsField nameFieldProps={StopLossField.name} labelFieldProps={StopLossField.label} />
//                         <NumberSettingsField nameFieldProps={CanselTime.name} labelFieldProps={CanselTime.label} />
//                         <NumberSettingsField nameFieldProps={CloseLimit.name} labelFieldProps={CloseLimit.label} />
//                         <NumberSettingsField nameFieldProps={LeverageField.name} labelFieldProps={LeverageField.label} />
//                         <NumberSettingsField nameFieldProps={PriceDifferenceOrderField.name} labelFieldProps={PriceDifferenceOrderField.label} />
//                     </div>

//                     <div className="col-span-2 border border-gary-400   p-4 rounded-lg gap-x-3">
//                         <div className="text-xl w-full flex justify-start">
//                             <span className="text-white">(take profit) های مرحله ای</span>
//                         </div >
//                         <div className=" flex flex-col  gap-x-4 pt-4">
//                             {rows.map((row, index) => (
//                                 <div key={row.index} className='grid grid-cols-1 sm:grid-cols-2 gap-x-3'>
//                                     {/* <input
//                                         type="text"
//                                         value={row.data1}
//                                         name={`data1_${row.id}`} // Unique name for data1
//                                         onChange={(e) => handleInputChange(e, row.id, 'data1')}
//                                     />
//                                     <input
//                                         type="text"
//                                         value={row.data2}
//                                         name={`data2_${row.id}`} // Unique name for data2
//                                         onChange={(e) => handleInputChange(e, row.id, 'data2')}
//                                     /> */}
//                                     <NumberSettingsField defaultValue={'0'} nameFieldProps={`${PriceDifferenceNumber.name}_${index + 1}`} labelFieldProps={`${PriceDifferenceNumber.label} ${index + 1}`} />
//                                     <NumberSettingsField defaultValue={'0'} nameFieldProps={`${PercentageNo.name}_${index + 1}`} labelFieldProps={`${PercentageNo.label} ${index + 1}`} />
//                                 </div>
//                             ))}
//                             <div className='flex w-full gap-x-6 justify-start pt-4'>
//                                 <Button color="primary"
//                                     // size="md"
//                                     className={{ extra: "md:px-4 md:py-2 p-2 w-24 sm:text-base text-xs    sm:w-44  rounded-lg" }}
//                                     onClick={addRow}
//                                 >افزودن سطر</Button>
//                                 <Button color="primary"
//                                     // size="md"
//                                     className={{ extra: "md:px-4 md:py-2 p-2 w-28 sm:text-base text-xs sm:w-44  rounded-lg" }}
//                                     onClick={removeRow}
//                                 >حذف آخرین سطر</Button>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="mt-3  sm:flex-rw flex-col flex gap-x-6 gap-y-2 pt-3 w-full px-3">
//                         <ToggleButtonField nameFieldProps={TreadDone.name} labelFieldProps={TreadDone.label} />
//                         <ToggleButtonField nameFieldProps={OpenOrderWithDemo.name} labelFieldProps={OpenOrderWithDemo.label} />
//                         <ToggleButtonField nameFieldProps={CloseMarket.name} labelFieldProps={CloseMarket.label} />
//                     </div>
//                 </div>
//                 <div className="flex gap-2 pt-4  justify-start">
//                     <>
//                         <Button
//                             color="primary"
//                             size="md"
//                             type="submit"
//                             className={{ extra: "px-4 py-2 rounded-lg" }}
//                         >
//                             اعمال تنظیمات
//                         </Button>
//                     </>
//                 </div>
//             </ReactHookFormWrapper>
//         </Card>

//     )
// }

// export default BingxSettings