import React from 'react';
import useSettings from './useSettings';
import Card from "../sections/Card";
import SettingSecondForm from './SettingSecondForm';
import SettingFirstForm from './SettingFirstForm';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

const Settings = () => {
    const { formMethods, onSubmit, formMethodsSecondForm, onSubmitSecondForm } = useSettings();

    return (
        <>
            <TabGroup>
                <TabList className="flex gap-4 pb-12">

                    <Tab
                        className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white"
                    >
                      قبل بازه (1) 
                    </Tab>
                    <Tab
                        className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white"
                    >
                          حین بازه (2)

                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Card title=' تنظیمات قبل بازه'>
                            <SettingFirstForm formMethods={formMethods} onSubmit={onSubmit} />
                        </Card>

                    </TabPanel>
                    <TabPanel>
                        <Card title=' تنظیمات حین بازه'>

                            <SettingSecondForm formMethodsSecondForm={formMethodsSecondForm} onSubmitSecondForm={onSubmitSecondForm} />
                        </Card>

                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </>
    );
}

export default Settings;
